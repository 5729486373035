import style from './common.module.css'
import type { IconProps } from '../../../../_types'

export function GenericAvatarIcon({
  title = 'Generic avatar icon',
  className,
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={[style.wrapper, className].join(' ')}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C5.38331 0 0 5.38331 0 12C0 18.6167 5.38331 24 12 24C18.6167 24 24 18.6167 24 12C24 5.38331 18.6167 0 12 0ZM12 0.999985C18.0654 0.999985 23 5.93456 23 12C23 14.4144 22.2088 16.6424 20.885 18.4585C20.7693 18.3833 20.655 18.3146 20.5391 18.2549C18.5352 17.2261 15.4267 16 12 16C8.57323 16 5.46481 17.2261 3.46044 18.2554C3.3448 18.3149 3.23066 18.3833 3.11506 18.4585C1.79117 16.6423 0.999969 14.4144 0.999969 12C0.999969 5.93456 5.93455 0.999985 12 0.999985ZM18 9C18 12.3137 15.3137 15 12 15C8.68629 15 6 12.3137 6 9C6 5.68629 8.68629 3 12 3C15.3137 3 18 5.68629 18 9Z"
      />
    </svg>
  )
}
