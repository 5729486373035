import style from './index.module.css'
import CloseIcon from '@library/_icons/close'
import type { Dispatch } from 'react'
import type { MenuAction } from '@library/header/me-menu/menu-reducer'
import { useState } from 'react'

export type Props = {
  dispatchMenuAction: Dispatch<MenuAction>
}

/**
 * Close menu
 * Also we remove the overflow hidden from the HTML
 * @param dispatchMenuAction
 * @constructor
 */
export default function CloseMenu({ dispatchMenuAction }: Props): JSX.Element {
  const [show, setShow] = useState<string>('')

  const closeMenu = () => {
    const mainWrapper = document.querySelector('html')
    mainWrapper?.style.setProperty('overflow', 'auto')
    dispatchMenuAction('hide')
  }

  return (
    <button className={style.close} onClick={closeMenu}>
      <CloseIcon />
    </button>
  )
}
