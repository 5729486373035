import { useAppSelector } from '@root/store'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import useCustomBasicStyles from '@hooks/use-custom-basic-styles'
import { selectSearchText } from '@store/search-slice/_selectors'

interface Props {
  channelPathname: string
}

export default function UseLoadOtherHooks({ channelPathname }: Props) {
  // Check if super channel
  const isSuperChannel =
    useAppSelector(selectChannelPropByChannelPathname(channelPathname)('superChannel')) ?? false

  // Check if channel has white label and load the custom styles
  const customStyles = useCustomBasicStyles()

  // Check if search has text
  const hasSearch = !!useAppSelector(selectSearchText)

  return {
    isSuperChannel,
    customStyles,
    hasSearch,
  }
}
