import { useCallback } from 'react'
import { clearSearchData, setSearchText, toggleInputVisibility } from '@store/search-slice'
import { useAppDispatch, useAppSelector } from '@root/store'
import { useRouter } from 'next/router'
import { selectSearchText } from '@store/search-slice/_selectors'

interface Props {
  channelPathname: string
  contentId?: string
}

export default function UseHandleClose({ channelPathname, contentId }: Props) {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const searchText = useAppSelector(selectSearchText)

  return useCallback(() => {
    // Clear results
    dispatch(clearSearchData({ searchText, channelPathname }))
    // Clear search text
    dispatch(setSearchText(''))
    // Hide search input
    dispatch(toggleInputVisibility())
    // Remove search query from url
    const currentPath = contentId ? `/${channelPathname}/${contentId}` : `/${channelPathname}`
    return router.replace(currentPath, '', { shallow: true })
  }, [dispatch, searchText, router, channelPathname, contentId])
}
