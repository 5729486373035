import style from './common.module.css'
import type { MenuAction } from './menu-reducer'
import type { Dispatch, ReactElement } from 'react'
import { MeMenuButton } from './menu-button'
import { MeMenuPopUp } from './menu-pop-up'
import { MeMenuPopUpMobile } from './menu-pop-up-mobile'
import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'
import { SHOW_MENU_MOBILE_UNDER } from '@root/constants'
import { parseBoolean } from '@utils/connascence'

export type Props = {
  className?: string
  dispatchMenuAction: Dispatch<MenuAction>
  isShowMenu: boolean
}

export function MeMenu({ className, dispatchMenuAction, isShowMenu }: Props): ReactElement {
  const [isMobile, setIsMobile] = useState(
    typeof window === 'object' ? window.innerWidth < SHOW_MENU_MOBILE_UNDER : false,
  )

  const checkIsMobile = throttle(() => {
    setIsMobile(window.innerWidth < SHOW_MENU_MOBILE_UNDER)
  }, 16)

  useEffect(() => {
    window.addEventListener('resize', checkIsMobile)
    return () => {
      window.removeEventListener('resize', checkIsMobile)
    }
  }, [checkIsMobile])

  return (
    <div className={[style.wrapper, className].join(' ')}>
      <MeMenuButton
        className={isShowMenu ? style.hideBtnWhenMenuIsOpen : ''}
        dispatchMenuAction={dispatchMenuAction}
        isShowMenu={isShowMenu}
      />

      {isMobile ? (
        <MeMenuPopUpMobile isShowMenu={isShowMenu} dispatchMenuAction={dispatchMenuAction} />
      ) : (
        <MeMenuPopUp
          className={style.popUp}
          isShowMenu={isShowMenu}
          dispatchMenuAction={dispatchMenuAction}
        />
      )}
    </div>
  )
}
