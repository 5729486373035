import {
  CHANNEL_ACCESS_DENIED_EMAIL_SESSION_KEY,
  CHANNEL_ACCESS_STATUS_SESSION_KEY,
  ChannelAccessStatusSessionEnum,
} from '@components/welcome-page/main/form-context-provider/section/channel-access-activate/constants'

export default function UseResetSessionAuthStorage(): void {
  sessionStorage.removeItem(CHANNEL_ACCESS_STATUS_SESSION_KEY)
  sessionStorage.removeItem(CHANNEL_ACCESS_DENIED_EMAIL_SESSION_KEY)
}
