import { useAppSelector as useSel } from '@root/store'
import {
  selectChannelPassesByPathname,
  selectChannelSubscriptionIdsByChannelPathname,
} from '@store/channel-slice/_selectors'
import { isEmptyArray } from '@utils/arrays'

export default function useHasSubscriptionsOrPass(channelPathname: string) {
  const subscriptionIds = useSel((state) =>
    selectChannelSubscriptionIdsByChannelPathname(state, channelPathname),
  )
  const passes = useSel((state) => selectChannelPassesByPathname(state, channelPathname))

  const hasSubscriptionOrPass = !isEmptyArray(subscriptionIds) || !isEmptyArray(passes)

  return { subscriptionIds, passes, hasSubscriptionOrPass }
}
