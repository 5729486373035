import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { VidzingPagesEnum } from '@shared/enums/user-billing/vidzing-pages.enum'

export default function useShowBillingPageIcon() {
  const router = useRouter()
  const path = router.asPath

  const [isInvalidPage, setIsInvalidPage] = useState(false)
  const [isBillingPage, setIsBillingPage] = useState(path.endsWith(VidzingPagesEnum.BILLING))
  const [isExplorePage, setIsExplorePage] = useState(path.endsWith(VidzingPagesEnum.EXPLORE))
  const [isCheerIqPage, setIsCheerIqPage] = useState(path.endsWith(VidzingPagesEnum.CHEER_DANCE_IQ))
  const [isMyVidzPage, setIsMyVidzPage] = useState(path.endsWith(VidzingPagesEnum.MY_VIDZ))

  useEffect(() => {
    // Determine if it's the billing page
    setIsBillingPage(path.endsWith(VidzingPagesEnum.BILLING))

    // Don't show billing button on these pages:
    setIsExplorePage(path.endsWith(VidzingPagesEnum.EXPLORE))
    setIsCheerIqPage(path.endsWith(VidzingPagesEnum.CHEER_DANCE_IQ))
    setIsMyVidzPage(path.endsWith(VidzingPagesEnum.MY_VIDZ))

    // Determine if page is invalid
    setIsInvalidPage(isBillingPage || isExplorePage || isCheerIqPage || isMyVidzPage)
  }, [path, router])

  return { isBillingPage, isExplorePage, isCheerIqPage, isInvalidPage, isMyVidzPage }
}
