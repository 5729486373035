import fbAuth from '@apis/firebase'
import Link from 'next/link'
import style from './common.module.css'
import type { MenuAction } from '../menu-reducer'
import { CREATOR_PORTAL_URL, HOUSE_RULES_URL, VIDZING_SUPPORT_LINK } from '@root/constants'
import { resetSubscriberBills } from '@store/subscriber-slice'
import { selectUserId, selectUserProps } from '@store/user-slice/_selectors'
import { userLogout } from '@store/user-slice'
import { useAppDispatch, useAppSelector as useSel } from '@store/index'
import { Dispatch, ReactElement, useCallback, useEffect, useRef } from 'react'
import UseResetSessionAuthStorage from '@hooks/use-reset-storage'
import { CREATOR_LINK_TEXT } from '@shared/constants/root.constants'
import { useRouter } from 'next/router'
import splitChannelUrl from '@root/utils/split-channel-url'
import goToStripeBillingPortal from '@apis/billing-microservice/go-to-stripe-billing-portal'
import RenderIf from '@root/utils/render-if'
import useGetSignupUrl from '@hooks/use-get-signup-url'
import { resetPassBills } from '@store/pass-slice'
import { AUTH_MENU, GUEST_MENU } from '@shared/constants/testing-tags.constants'
import { parseBoolean } from '@utils/connascence'
import RenderByFlag from '@utils/render-by-flag'
import { UrlPathsEnum } from '@shared/enums/layout/url-paths.enum'
import useShowBillingPageIcon from '@hooks/use-show-billing-page-icon'
import { useSelector } from 'react-redux'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import useContentLogout from '@hooks/_content/use-content-logout'

export type Props = {
  className?: string
  dispatchMenuAction: Dispatch<MenuAction>
  isShowMenu: boolean
}

export function MeMenuPopUp({ className, dispatchMenuAction, isShowMenu }: Props): JSX.Element {
  const userId = useSel(selectUserId)
  const userType = useSel(selectUserProps('userType'))
  const reduxDispatch = useAppDispatch()
  //
  const router = useRouter()
  const path = router.asPath
  const { channelSlug } = splitChannelUrl(path)
  const { signupUrl, loginUrl, billingUrl, hasWhiteLabel } = useGetSignupUrl(channelSlug)
  const { isInvalidPage } = useShowBillingPageIcon()

  const listElement = useRef<HTMLUListElement>(null)

  const { fetchContentDataOnLogout } = useContentLogout()

  const logOut = async () => {
    UseResetSessionAuthStorage()
    await fbAuth.signOut().then(() => {
      fetchContentDataOnLogout()
    })
    reduxDispatch(userLogout())
    reduxDispatch(resetSubscriberBills())
    reduxDispatch(resetPassBills())
    // Clear local storage on logout so creator redux persist data is removed if user logs out
    localStorage.clear()
  }

  const handleBillingNavigation = async (event) => {
    event.preventDefault()
    if (!isInvalidPage) {
      await router.push(billingUrl)
    }
  }

  const handleMyVidzNavigation = async (event) => {
    event.preventDefault()
    await router.push('/myvidz')
  }

  const closeMenu = useCallback(() => {
    dispatchMenuAction('hide')
  }, [dispatchMenuAction])

  useEffect(() => {
    window.addEventListener('resize', closeMenu)
    return () => {
      window.removeEventListener('resize', closeMenu)
    }
  }, [closeMenu])

  // Creator link
  const creatorLink =
    userType !== 'viewer' && !hasWhiteLabel ? (
      <li>
        <a className={style.item} href={'/creator'}>
          {CREATOR_LINK_TEXT}
        </a>
      </li>
    ) : null

  const guestMenu = (
    <ul
      className={[style.list, isShowMenu ? '' : style.hide, className].join(' ')}
      ref={listElement}
      data-id={GUEST_MENU}
    >
      {/* If white label render only log in option and user can go to sign up from login */}
      <RenderIf isTrue={hasWhiteLabel}>
        <li>
          <Link href={loginUrl}>
            <a className={style.item}>Log in</a>
          </Link>
        </li>
      </RenderIf>
      {/* If not white label text is sign up or log in because we handle both flows from one page */}
      <RenderIf isTrue={!hasWhiteLabel}>
        <li>
          <Link href={signupUrl}>
            <a className={style.item}>{hasWhiteLabel ? 'Sign up' : 'Sign up or Log in'}</a>
          </Link>
        </li>
      </RenderIf>
      <RenderIf isTrue={!hasWhiteLabel}>
        <hr className={style.hr} />
        <li>
          <a className={style.item} href={CREATOR_PORTAL_URL} rel="noreferrer">
            Become a Creator
          </a>
        </li>
        <li>
          <Link href={UrlPathsEnum.BLOG}>
            <a className={style.item} rel="noreferrer" target={'_blank'}>
              Blog
            </a>
          </Link>
        </li>
        <li>
          <a href={VIDZING_SUPPORT_LINK} rel="noreferrer" target={'_blank'}>
            Help
          </a>
        </li>
      </RenderIf>
    </ul>
  )

  const authMenu: ReactElement = (
    <ul
      className={[style.list, isShowMenu ? '' : style.hide, className].join(' ')}
      ref={listElement}
      data-id={AUTH_MENU}
    >
      {creatorLink}
      <RenderIf isTrue={!hasWhiteLabel}>
        <li>
          <button className={style.item} onClick={handleMyVidzNavigation}>
            My Vidz
          </button>
        </li>
      </RenderIf>
      <RenderIf isTrue={!isInvalidPage}>
        <li>
          <button className={style.item} onClick={handleBillingNavigation}>
            Billing
          </button>
        </li>
      </RenderIf>
      <li>
        <button className={style.item} onClick={logOut}>
          Log Out
        </button>
      </li>
      <RenderIf isTrue={!hasWhiteLabel}>
        <hr className={style.hr} />
        <li>
          <Link href={UrlPathsEnum.BLOG}>
            <a className={style.item} rel="noreferrer" target={'_blank'}>
              Blog
            </a>
          </Link>
        </li>
        <li>
          <a href={VIDZING_SUPPORT_LINK} rel="noreferrer" target={'_blank'}>
            Help
          </a>
        </li>
        <li>
          <Link href={HOUSE_RULES_URL}>
            <a className={style.item} rel="noreferrer">
              House Rules
            </a>
          </Link>
        </li>
        {/* TODO: No more hubspot, direct to accessibility statement on Vidzing when ready */}
        {/*<li>*/}
        {/*  <a className={style.item} href={''} rel="noreferrer">*/}
        {/*    Accessibility Statement*/}
        {/*  </a>*/}
        {/*</li>*/}
      </RenderIf>
    </ul>
  )

  return userId ? authMenu : guestMenu
}
