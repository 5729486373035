export type MenuAction = 'toggle' | 'show' | 'hide'

export function menuReducer(isShowMenu: boolean, action: MenuAction): boolean {
  switch (action) {
    case 'toggle':
      return !isShowMenu
    case 'show':
      return true
    case 'hide':
    default:
      return false
  }
}
