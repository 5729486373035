import styles from './style.module.scss'
import { InputField } from '@library/_form/input-field'
import { setSearchText, toggleInputVisibility, toggleLoading } from '@store/search-slice'
import { useAppDispatch, useAppSelector } from '@root/store'
import {
  selectInputVisibility,
  selectIsLoading,
  selectSearchData,
  selectSearchText,
} from '@store/search-slice/_selectors'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { MagnifyingGlassIcon } from '@library/_form/input-field/_icons/magnifying-glass-icon'
import { useRouter } from 'next/router'
import { searchText } from '@store/search-slice/_thunks'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import RenderIf from '@root/utils/render-if'
import { CloseButton } from '@library/backdrop/close-button'
import UseHandleClose from '@hooks/search/use-handle-close'
import { clsx } from 'clsx'
import useChannelSearch from '@hooks/search/use-channel-search'
import useDebounce from '@hooks/use-debounce'
import { ONE_SECOND_MS } from '@root/constants'

export const SEARCH_ID = 'search-form'
export const SEARCH_PLACEHOLDER = 'Search by title or description'

interface SearchProps {
  isMobile: boolean
}

export function Search({ isMobile }: SearchProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null)
  const searchValue = useAppSelector(selectSearchText)
  const inputVisibility = useAppSelector(selectInputVisibility)
  const router = useRouter()
  const { channel_pathname }: { channel_pathname?: string } = router.query
  //
  const [searchBy, setSearchBy] = useState<string>('')

  // Check if super channel and pass organisationId to search
  const isSuperChannel =
    useAppSelector(selectChannelPropByChannelPathname(channel_pathname)('superChannel')) ?? false
  const organisationId = useAppSelector(
    selectChannelPropByChannelPathname(channel_pathname)('organisation'),
  )?.organisationId

  // If search button is clicked, focus on input
  useEffect(() => {
    if (inputVisibility) {
      inputRef.current?.focus()
    }
  }, [inputVisibility])

  const { setSearchValue, toggleInput, handleClose, onKeyDown } = useChannelSearch()

  const triggerSearchBy = () => {
    setSearchValue(searchBy)
  }

  const handleTriggerFunction = useDebounce(ONE_SECOND_MS, triggerSearchBy)

  useEffect(() => {
    handleTriggerFunction()
  }, [searchBy])

  return (
    <div className={clsx({ [styles.search]: true, [styles.expand]: inputVisibility })}>
      <InputField
        id={SEARCH_ID}
        setValue={setSearchBy}
        value={searchBy}
        type={'search'}
        inputRef={inputRef}
        customInputClassName={styles.searchInput}
        placeholder={inputVisibility ? SEARCH_PLACEHOLDER : ''}
        disabled={!inputVisibility}
        onKeyDown={onKeyDown}
        searchComponent={
          <>
            <RenderIf isTrue={!inputVisibility}>
              <button
                className={`${styles.icon} ${styles.searchIcon}`}
                type="button"
                onClick={toggleInput}
                disabled={inputVisibility}
              >
                {<MagnifyingGlassIcon />}
                <RenderIf isTrue={!isMobile}>
                  <span className={inputVisibility ? styles.hidden : ''}>Search</span>
                </RenderIf>
              </button>
            </RenderIf>

            <RenderIf isTrue={isMobile && inputVisibility}>
              <CloseButton
                className={`${styles.icon} ${styles.closeIcon}`}
                onClick={() => handleClose()}
              />
            </RenderIf>
            <RenderIf isTrue={!isMobile && inputVisibility}>
              <CloseButton
                className={`${styles.icon} ${styles.closeIconDesktop}`}
                onClick={() => handleClose()}
              />
            </RenderIf>
          </>
        }
      />
    </div>
  )
}
