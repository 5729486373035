import { useCallback, useEffect, useRef } from 'react'
import { setSearchText, toggleInputVisibility, toggleLoading } from '@store/search-slice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@root/store'
import { selectIsLoading, selectSearchData, selectSearchText } from '@store/search-slice/_selectors'
import { useRouter } from 'next/router'
import { searchText } from '@store/search-slice/_thunks'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import UseHandleClose from '@hooks/search/use-handle-close'
import { SEARCH_PAGE_PATH } from '@shared/constants/search.constants'
import { isEmptyString } from '@utils/strings'

export default function useChannelSearch() {
  // App  hooks
  const dispatch = useDispatch()
  const router = useRouter()
  const { channel_pathname, search }: { channel_pathname?: string; search?: string } = router.query
  const currentPage = router.pathname
  const isInSearch = currentPage.includes(SEARCH_PAGE_PATH)

  // Selectors
  const isLoading = useAppSelector(selectIsLoading)
  const searchValue = useAppSelector(selectSearchText)
  const isSuperChannel =
    useAppSelector(selectChannelPropByChannelPathname(channel_pathname)('superChannel')) ?? false
  const organisationId = useAppSelector(
    selectChannelPropByChannelPathname(channel_pathname)('organisation'),
  )?.organisationId
  const channelId = useAppSelector(
    selectChannelPropByChannelPathname(channel_pathname)('channelId'),
  )

  const searchData = useAppSelector((state) =>
    selectSearchData(state, {
      channelPathname: channel_pathname,
      searchText: searchValue,
    }),
  )

  // Refs
  const abortRef = useRef(null)

  const triggerSearch = (searchBy: string) => {
    //Set abort signal to axios
    abortRef.current = new AbortController()
    dispatch(
      searchText({
        searchText: searchBy,
        signal: abortRef.current.signal,
        searchParams: {
          ...(isSuperChannel ? { organisationId } : { channelId }),
          // Enable chapter search
          chapterSearch: true,
        },
        channelPathname: channel_pathname,
      }),
    )
  }

  const setSearchValue = (searchText: string) => {
    // Don't do anything if the search is empty
    if (isEmptyString(searchText)) return
    // Set search text
    dispatch(setSearchText(searchText))
    triggerSearch(searchText)

    if (!isInSearch) {
      // Update url
      return router.replace(
        {
          query: { ...router.query, search: searchText },
        },
        '',
        { shallow: true },
      )
    }
  }

  // Toggle between input and search button
  const toggleInput = useCallback(() => dispatch(toggleInputVisibility()), [dispatch])

  // onKey down
  const onKeyDown = useCallback(
    (event) => {
      if ((event.key === 'Backspace' || event.key === 'Delete') && searchValue === '') {
        // When input is empty and backspace or delete is pressed, show search button
        dispatch(toggleInputVisibility())

        // Remove search query from url
        return router.replace(`/${channel_pathname}`, '', { shallow: true })
      }
    },
    [channel_pathname, dispatch, router, searchValue],
  )

  // Handle close on mobile
  const handleClose = UseHandleClose({ channelPathname: channel_pathname })

  return { setSearchValue, toggleInput, handleClose, onKeyDown, triggerSearch }
}
