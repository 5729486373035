import fbAuth from '@apis/firebase'
import Link from 'next/link'
import style from './styles.module.scss'
import type { MenuAction } from '../menu-reducer'
import { HOUSE_RULES_URL, VIDZING_HOME_PAGE, VIDZING_SUPPORT_LINK } from '@root/constants'
import { resetSubscriberBills } from '@store/subscriber-slice'
import { userLogout } from '@store/user-slice'
import { selectUserId, selectUserProps } from '@store/user-slice/_selectors'
import { useAppDispatch, useAppSelector as useSel } from '@store/index'
import { Dispatch, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import Exclude from '@library/_icons/exclude'
import CopyRight from '@library/footer/copyRight'
import VidzingVersion from '@library/header/me-menu/version'
import UseResetSessionAuthStorage from '@hooks/use-reset-storage'
import { CREATOR_LINK_TEXT } from '@shared/constants/root.constants'
import { useRouter } from 'next/router'
import splitChannelUrl from '@root/utils/split-channel-url'
import RenderIf from '@root/utils/render-if'
import useGetSignupUrl from '@hooks/use-get-signup-url'
import { hasProp } from '@root/utils/has-prop'
import useCustomBasicStyles from '@hooks/use-custom-basic-styles'
import { resetPassBills } from '@store/pass-slice'
import { UrlPathsEnum } from '@shared/enums/layout/url-paths.enum'
import useShowBillingPageIcon from '@hooks/use-show-billing-page-icon'
import { useSelector } from 'react-redux'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { AnimatePresence, motion } from 'framer-motion'
import useContentLogout from '@hooks/_content/use-content-logout'

export type Props = {
  className?: string
  dispatchMenuAction: Dispatch<MenuAction>
  isShowMenu: boolean
}

export function MeMenuPopUpMobile({
  className,
  dispatchMenuAction,
  isShowMenu,
}: Readonly<Props>): ReactElement {
  const userId = useSel(selectUserId)
  const email = useSel(selectUserProps('email')) ?? ''
  const name = useSel(selectUserProps('firstName')) ?? ''
  const userType = useSel(selectUserProps('userType'))
  const reduxDispatch = useAppDispatch()

  const listElement = useRef<HTMLUListElement>(null)
  //
  const router = useRouter()
  const path = router.asPath
  const { channelSlug } = splitChannelUrl(path)
  const { signupUrl, loginUrl, billingUrl, hasWhiteLabel } = useGetSignupUrl(channelSlug)

  const { isBillingPage, isInvalidPage } = useShowBillingPageIcon()

  const { fetchContentDataOnLogout } = useContentLogout()

  const customStyles = useCustomBasicStyles()
  // Initialize css variables
  // If customStyles is defined use the custom main colour as background for users auth button
  // otherwise use the default skyEarthGradient
  const cssVariables: Record<string, unknown> = {
    '--background': customStyles ? customStyles['--customMainColour'] : 'var(--skyEarthGradient)',
  }

  const closeMenu = useCallback(() => {
    dispatchMenuAction('hide')
  }, [dispatchMenuAction])

  useEffect(() => {
    const removeAddScrollBlock = () => {
      const mainWrapper = document.querySelector('html')
      mainWrapper?.style.setProperty('overflow', 'auto')
    }

    if (isShowMenu) {
      listElement?.current?.childNodes.forEach((item) => {
        if (item instanceof HTMLElement) {
          const addListener = item.dataset?.action !== 'keep'
          if (addListener) {
            item.addEventListener('click', removeAddScrollBlock)
          }
        }
      })
    }
  }, [isShowMenu, userId])

  useEffect(() => {
    window.addEventListener('resize', closeMenu)
    return () => {
      window.removeEventListener('resize', closeMenu)
    }
  }, [closeMenu])

  //Creator link
  const creatorLink = useMemo(() => {
    return userType === 'creator' && !hasWhiteLabel ? (
      <li>
        <a className={style.icon} href={'/creator'}>
          {CREATOR_LINK_TEXT}
        </a>
      </li>
    ) : null
  }, [userType, hasWhiteLabel])

  /**
   * Render Sign in or Logout button
   */
  const logoutSignInButton = useMemo(() => {
    const logOut = async () => {
      UseResetSessionAuthStorage()
      await fbAuth.signOut().then(() => {
        fetchContentDataOnLogout()
      })
      reduxDispatch(userLogout())
      reduxDispatch(resetSubscriberBills())
      reduxDispatch(resetPassBills())
      // Clear local storage on logout so creator redux persist data is removed if user logs out
      localStorage.clear()
    }

    /**
     * Auth button
     */
    const authButton = (
      <li data-action={'keep'} className={style.authMenu}>
        <span className={style.name} style={cssVariables}>
          {(name || email).charAt(0)}
        </span>
        <button className={style.item} onClick={logOut}>
          Log Out
        </button>
      </li>
    )

    /**
     * Guess Button
     */
    const guestButton = (
      <>
        {/* If white label render only log in option and user can go to sign up from login */}
        <RenderIf isTrue={hasWhiteLabel}>
          <li>
            <div className={style.guestMenu}>
              <Exclude className={style.icon} />
              <Link href={loginUrl}>
                <a className={style.item}>Log in</a>
              </Link>
            </div>
          </li>
        </RenderIf>
        {/* If not white label text is sign up or log in because we handle both flows from one page */}
        <RenderIf isTrue={!hasWhiteLabel}>
          <li>
            <div className={style.guestMenu}>
              <Exclude className={style.icon} />
              <Link href={signupUrl}>
                <a className={style.item}>Sign up or Log in</a>
              </Link>
            </div>
          </li>
        </RenderIf>
      </>
    )

    return userId ? authButton : guestButton
  }, [cssVariables, name, email, hasWhiteLabel, loginUrl, signupUrl, userId, reduxDispatch])

  // Framer motion animation
  const exitAnimation = { opacity: 0, y: -120 }
  const menuAnimation = {
    visible: { y: 0, opacity: 1 },
    hidden: exitAnimation,
    exit: exitAnimation,
  }

  return (
    <AnimatePresence>
      {isShowMenu && (
        <motion.ul
          initial="hidden"
          animate="visible"
          exit={'exit'}
          variants={menuAnimation}
          className={[
            style.list,
            className,
            hasWhiteLabel && !isBillingPage ? style.customChannelList : '',
            isBillingPage ? style.billingPage : '',
          ].join(' ')}
          ref={listElement}
        >
          {/* Log out / sign in button should always be at the bottom of the list */}
          {logoutSignInButton}

          {/* creatorLink renders the "Switch to Creating" button for viewers that are also creators */}
          {creatorLink}

          {/* Render my vidz section based on the feature flag and if channel is not white labelled */}
          <RenderIf isTrue={!hasWhiteLabel && hasProp(userId)}>
            <li>
              <Link href={'/myvidz'}>My Vidz</Link>
            </li>
          </RenderIf>

          <RenderIf isTrue={hasProp(userId) && !isInvalidPage}>
            <li>
              <Link href={billingUrl}>
                <a>Billing</a>
              </Link>
            </li>
          </RenderIf>

          <RenderIf isTrue={!hasWhiteLabel}>
            <div className={style.spacer} />
            <li>
              <span className={style.info}>Vidzing</span>
            </li>
            <li>
              <Link href={UrlPathsEnum.BLOG}>
                <a className={style.item} rel="noreferrer" target={'_blank'}>
                  Blog
                </a>
              </Link>
            </li>
            <li>
              <a href={VIDZING_SUPPORT_LINK} rel="noreferrer" target={'_blank'}>
                Help
              </a>
            </li>
            <li>
              <Link href={HOUSE_RULES_URL}>House Rules</Link>
            </li>
            {/* TODO: No more hubspot, direct to accessibility statement on Vidzing when ready */}
            {/*<li><a href={ACCESSIBILITY_STATEMENT_EXTERNAL_URL}>Accessibility Statement</a></li>*/}
            <div className={style.spacer} />
            <li>
              <span className={style.info}>Creators</span>
            </li>
            <li>
              <a href={VIDZING_HOME_PAGE}>Become a Creator</a>
            </li>
            {/* TODO: No more hubspot, direct to new blog hosting when ready */}
            {/*<li><a href={HUBSPOT_BLOG_URL}>Blog</a></li>*/}
            {/*<li><a href={CASE_STUDY_URl}>Case Studies</a></li>*/}
            <li className={style.version}>
              <CopyRight variant={'other'} />
              <VidzingVersion />
            </li>
          </RenderIf>
        </motion.ul>
      )}
    </AnimatePresence>
  )
}
