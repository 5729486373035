import { useSelector } from 'react-redux'
import { selectCustomChannel } from '@store/custom-channel/_selector'
import UseSelectWhiteLabelData from 'hooks/white-label/use-select-white-label-data'

export default function useCustomBasicStyles() {
  const { whiteLabel } = UseSelectWhiteLabelData()

  const {
    backgroundColour,
    mainColour,
    secondaryColour,
    fontFormColour,
    inputBackgroundColour,
    themeColour,
    themeContrastColour,
    greyThemeColour,
  } = useSelector(selectCustomChannel)

  const customStyles: Record<string, unknown> = {
    '--customBackgroundColour': backgroundColour,
    '--customMainColour': mainColour,
    '--customSecondaryColour': secondaryColour,
    '--customFormFontColour': fontFormColour,
    '--customInputBackgroundColor': inputBackgroundColour,
    '--customThemeColour': themeColour,
    '--customThemeContrastColour': themeContrastColour,
    '--customGreyThemeColour': greyThemeColour,
  }

  return whiteLabel ? customStyles : undefined
}
