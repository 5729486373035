export type Props = {
  className?: string
}

export default function CloseIcon({ className }: Props): JSX.Element {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.292893 1.70711C-0.0976318 1.31658 -0.0976303 0.683418 0.292894 0.292893C0.683417 -0.0976315 1.31658 -0.0976308 1.70711 0.292894L8 6.58579L14.2929 0.292894C14.6834 -0.0976308 15.3166 -0.0976315 15.7071 0.292893C16.0976 0.683418 16.0976 1.31658 15.7071 1.70711L9.41422 8.00001L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.41422L1.70712 15.7071C1.31659 16.0976 0.683429 16.0976 0.292904 15.7071C-0.0976204 15.3166 -0.0976206 14.6834 0.292903 14.2929L6.58579 8.00001L0.292893 1.70711Z"
        fill="white"
      />
    </svg>
  )
}
