export const CHANNEL_ACCESS_LOGO_WIDTH = 72

// Channel Access email form
export const CHANNEL_ACCESS_EMAIL_LABEL = 'Email address'
export const CHANNEL_ACCESS_EMAIL = 'channel_access_email'

// storage keys
export const CHANNEL_PATHNAME_ACCESS_SESSION_KEY = 'access-channelPathname'
export const CHANNEL_ID_ACCESS_SESSION_KEY = 'access-channelId'
export const CHANNEL_ACCESS_CODE_SESSION_KEY = 'access-code'
export const CHANNEL_ACCESS_STATUS_SESSION_KEY = 'channel-access-status'
export const CHANNEL_ACCESS_DENIED_EMAIL_SESSION_KEY = 'channel-access-denied-email'
export const CHANNEL_ACCESS_SELF_ACTIVATE_CODE = 'channel-access-self-activation'

export enum ChannelAccessStatusSessionEnum {
  DENIED = 'denied',
  ALLOWED = 'allowed',
}
