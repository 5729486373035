import { RootState } from '@root/store'
import { createSelector } from 'reselect'
import { SelectSearchDataProps } from '@store/search-slice/_types'

/**
 * Select search text
 */
export const selectSearchText = ({ search }: RootState) => search.searchText

/**
 * Select search input visibility
 */
export const selectInputVisibility = ({ search }: RootState) => search.inputVisibility

/**
 * Select search data by channel path name and search text
 */
export const selectSearchData = createSelector(
  [
    ({ search }: RootState) => search,
    (state, selectSearchPayload: SelectSearchDataProps) => selectSearchPayload,
  ],
  (search, selectSearchPayload) => {
    const { searchText, channelPathname } = selectSearchPayload
    if (searchText && channelPathname) {
      const stringKey = encodeURI(searchText)
      return search?.resultsByChannelPathnameAndSearchText?.[channelPathname]?.[stringKey]
    }
    return undefined
  },
)

/**
 * Select search loading state
 */
export const selectIsLoading = ({ search }: RootState) => search.isLoading
