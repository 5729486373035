import style from './common.module.css'
import type { MenuAction } from '../menu-reducer'
import { BurgerIcon } from './burger-icon'
import { Dispatch, useEffect, useRef } from 'react'
import { GenericAvatarIcon } from './generic-avatar-icon'
import { selectUserId, selectUserProps } from '@store/user-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import useCheckIfMobile from '@hooks/use-check-if-mobile'
import { SHOW_MENU_MOBILE_UNDER } from '@root/constants'
import useCustomBasicStyles from '@hooks/use-custom-basic-styles'
import splitChannelUrl from '@utils/split-channel-url'
import useGetSignupUrl from '@hooks/use-get-signup-url'
import { useRouter } from 'next/router'
import { MENU_BUTTON } from '@shared/constants/testing-tags.constants'
import { parseBoolean } from '@utils/connascence'

export type Props = {
  className?: string
  dispatchMenuAction: Dispatch<MenuAction>
  isShowMenu: boolean
}

export function MeMenuButton({ className, dispatchMenuAction, isShowMenu }: Props): JSX.Element {
  const refBtn = useRef<HTMLButtonElement>()
  const userId = useSel(selectUserId)
  const email = useSel(selectUserProps('email')) ?? ''
  const name = useSel(selectUserProps('firstName')) ?? ''
  const customStyles = useCustomBasicStyles()

  const isNewTheme = parseBoolean(process.env.VIDZING_THEME_2024)

  const backgroundToUse = isNewTheme ? 'var(--offBlackNew)' : 'var(--skyEarthGradient)'
  const fillToUse = isNewTheme ? 'var(--offBlackNew)' : 'var(--vidzingNewNight)'
  const authStyleToUse = isNewTheme ? style.authButtonV2 : style.authButton
  const guestStyleToUse = isNewTheme ? style.guestButtonV2 : style.guestButton

  // Initialize css variables
  // If customStyles is defined use the custom main colour as background for users auth button
  // otherwise use the default skyEarthGradient
  const cssVariables: Record<string, unknown> = {
    '--background': customStyles ? customStyles['--customMainColour'] : backgroundToUse,
    '--fillColor': customStyles ? 'var(--offBlack)' : fillToUse,
  }

  const isMobile = useCheckIfMobile(SHOW_MENU_MOBILE_UNDER)

  // White label data
  const router = useRouter()
  const path = router.asPath
  const { channelSlug } = splitChannelUrl(path)
  const { hasWhiteLabel, loginUrl } = useGetSignupUrl(channelSlug)

  /** ON mobile version, we remove the scroll when the menu is opened */
  const toggleMenu = async () => {
    // If white label then the me-menu if guest only has login button so no need to popup just route straight to login
    if (hasWhiteLabel && !userId) {
      router.push(loginUrl)
      return
    }

    if (isMobile) {
      const mainWrapper = document.querySelector('html')
      mainWrapper?.style.setProperty('overflow', 'hidden')
    }

    await dispatchMenuAction('toggle')
  }

  const guestButton = (
    <>
      Log in
      {isMobile && !hasWhiteLabel ? (
        <BurgerIcon className={style.icon} />
      ) : (
        <>
          <hr className={style.hr} /> <GenericAvatarIcon className={style.icon} />
        </>
      )}
    </>
  )

  const authButton = (
    <>
      <BurgerIcon className={style.icon} />
      <span className={style.name}>{(name || email).charAt(0)}</span>
    </>
  )

  useEffect(() => {
    const closeMenuOnClick = (event: MouseEvent) => {
      if (event.target !== refBtn.current) {
        dispatchMenuAction('hide')
      }
    }

    if (isShowMenu) {
      window.addEventListener('click', closeMenuOnClick)
    } else {
      window.removeEventListener('click', closeMenuOnClick)
    }

    return () => {
      window.removeEventListener('click', closeMenuOnClick)
    }
  }, [dispatchMenuAction, isShowMenu])

  return (
    <button
      ref={refBtn}
      className={[style.base, userId ? authStyleToUse : guestStyleToUse, className].join(' ')}
      onClick={toggleMenu}
      style={cssVariables}
      data-id={MENU_BUTTON}
    >
      {userId ? authButton : guestButton}
    </button>
  )
}
