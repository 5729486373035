import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector as useSel } from '@root/store'
import { getContentByIdThunk } from '@store/contents-slice/_thunks'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import { isEmptyString } from '@utils/strings'

/**
 * Fetch content data when the user logout.
 * This allows us to continue playing lead magnets
 */
export default function useContentLogout() {
  const router = useRouter()
  const dispatch = useAppDispatch()
  //TODO: Fix this on Next 16
  const { channel_pathname, content_pathname } = router.query

  const channelPathname = channel_pathname as string
  const contentId = content_pathname as string
  const channelId = useSel(
    selectChannelPropByChannelPathname(channelPathname as string)('channelId'),
  )

  const fetchContentDataOnLogout = () => {
    if (!isEmptyString(channelId) && !isEmptyString(contentId)) {
      dispatch(getContentByIdThunk({ contentId, channelId }))
    }
  }

  return { fetchContentDataOnLogout }
}
